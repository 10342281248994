.linear-bar {
  margin: 15px;
}

.gene-record-container {
  padding: 20px;
  background-color: var(--background);
  border-radius: 5px;

  .gene-record-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: end; // isn't aligning name object correctly
    margin-bottom: 0.5em;

    .symbol {
      font-weight: bold;
      font-size: 36px;
      color: var(--text-primary);
    }

    .concept-id {
      color: var(--text-accent);
      font-size: 24px;
      margin-left: 0.75em;
      margin-bottom: 0.22em;
    }

    .concept-id-link {
      color: var(--text-accent) !important;
      text-decoration: none !important;
    }

    .concept-id-link:hover {
      text-decoration: underline !important;
    }

    .concept-id-link:active {
      text-decoration: underline !important;
    }
  }

  .data-box {
    padding: 1rem;
    border: solid white 1px;

    .box-title {
      color: var(--text-primary);
      font-size: 1.4rem;
      font-weight: bold;
    }

    .box-content {
      padding: 0.5rem;
      max-height: 570px;

      table > tbody > tr {
        line-height: 1.6rem;
        border: 1px black;
      }

      .box-item {
        line-height: 1.55rem;
      }

      .attribute-name {
        font-weight: 500;
      }

      .attribute-value {
        color: var(--text-primary);
      }

      .box-link {
        margin-bottom: 10px;
      }
    }
  }

  .gene-record-upper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 0px;
    margin-bottom: 15px;

    .gene-record-info {
      grid-area: 1 / 1 / 2 / 4;
      max-height: 400px;
      overflow: scroll;
    }
    .gene-record-categories {
      grid-area: 1 / 4 / 2 / 6;
      max-height: 400px;
      overflow: scroll;
    }
  }

  .gene-record-lower {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    .gene-record-aliases {
      grid-area: 1 / 1 / 3 / 3;
      overflow: scroll;
      max-height: 500px;
    }
    .gene-record-publications {
      grid-area: 3 / 1 / 5 / 3;
      overflow: scroll;
      max-height: 500px;
    }
    .gene-record-table {
      grid-area: 1 / 3 / 5 / 7;
    }
  }
}

.meta-link {
  color: var(--header-gradient-1) !important;
  text-decoration: none !important;
}

.meta-link:hover {
  text-decoration: underline !important;
}

.meta-link:active {
  text-decoration: underline !important;
}
