html,
body {
  background-color: var(--background);
  margin: 0;
  font-family: 'Lato';
  color: var(--text-content);

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--text-content);
  }
}

.layout-container {
  background-color: var(--background-light);
  color: var(--text-content);
  min-height: 100vh;
  position: relative;

  .content-container {
    background-color: var(--background-light);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    padding-bottom: 75px;
    min-height: 100%;
  }

  .content {
    background-color: var(--background);
    min-height: 75vh;
  }
}

header {
  background: -webkit-linear-gradient(
    var(--header-gradient-1),
    var(--header-gradient-2)
  );

  .header-logo {
    font-family: 'Fira Sans', sans-serif;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 80px;
    margin: 3px 0 0 20px;
    background: -webkit-linear-gradient(
      var(--logo-gradient-1),
      var(--logo-gradient-2)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }

  .browse-button {
    text-transform: none;
    font-size: 18px;
    font-weight: 100;
    color: var(--background);
  }

  .header-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    background-color: #f0e7c0;
    padding: 10px;
  }

  nav {
    flex-grow: 1;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    align-items: center;
    height: 80px;

    ul {
      color: white;
      display: flex;
      justify-content: flex-end;
      list-style: none;
      margin: 0 60px;
      padding: 12px 0;

      li {
        margin-left: 60px;
        line-height: 46px;
        font-family: 'Work Sans';
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}

footer {
  background-color: var(--theme-primary);
  color: white;
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: 20px;
  font-family: 'Work Sans';
  font-weight: 300;
  position: absolute;
  bottom: 0;
}
