.table-header {
  background-color: var(--background-light);
  font-size: 14px;
}

.column-menu-button {
  visibility: visible !important;
  width: 35px !important;
}

.table-cell {
  cursor: pointer;
}

.footer {
  align-items: baseline;
}

.data-grid {
  min-width: 600px;
}

.interaction-table-container {
  background-color: var(--background);
}

.gene-cell {
  margin: 15px 0 15px 0;
}

.drug-cell {
  margin: 15px 0 15px 0;
}

.record-link {
  color: var(--header-gradient-1);
  text-decoration: none;
}

.record-link:hover {
  text-decoration: underline;
}

.record-link:active {
  text-decoration: underline;
}
