.categories-table-header {
  background-color: var(--background-light);
  font-size: 14px;
}

.categories-footer {
  display: false;
}

.source-chip {
  margin: 2px;
}

.categories-table-header {
  height: fit-content;
}
