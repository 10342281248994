.gene-summary-container {
  color: var(--text-content);
  margin: 0 15px;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    color: var(--text-content);
  }

  h4 {
    color: var(--text-content);
  }

  @media screen and (max-width: 768px) {
    .gene-summary-content {
      display: block !important;
    }
    .interaction-count-container {
      max-width: 100% !important;
      border: none !important;
      margin-bottom: 20px;
    }
    .summary-infographic-container {
      max-width: 100% !important;
      border: none !important;
      margin-bottom: 20px;
      margin-left: 0 !important;
    }
    .chart-section {
      flex-direction: column;
    }
  }

  .gene-summary-content {
    display: flex;
    background-color: var(--background);

    .interaction-count-container {
      border: 1px var(--text-content) solid;
      padding: 10px;
      max-width: fit-content;
      flex-grow: 1;

      .interaction-count-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .interaction-count-row {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;

        &:hover {
          background-color: var(--background-light);
          cursor: pointer;
        }
      }

      .interaction-count-gene {
        margin-right: 1.5em;
      }

      .filtered-by {
        background-color: var(--background-light);
      }
    }

    .summary-infographic-container {
      margin-left: 5px;
      border: 1px var(--text-content) solid;
      padding: 10px;
      display: flex;
      flex-direction: column;
      min-height: 500px;
      width: 100%;
      overflow-x: scroll;

      .tabbed-view {
        align-items: flex-start !important;
        justify-content: center;
        min-width: fit-content;
      }

      .chart-section {
        height: 100%;
        width: 100%;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: left;

        .score-container {
          padding-left: 10px;
        }
        .type-container {
          padding-left: 10px;
        }
        .approval-container {
          padding-left: 10px;
        }
      }
      .chart-selector {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
      }
    }
  }
}

canvas {
  max-height: 500px !important;
  max-width: 500px !important;
  min-height: 500px !important;
  min-width: 550px !important;
}
