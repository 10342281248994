.gene-list-table-container {
  min-height: 500px;
}

.loading {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}
