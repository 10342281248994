.downloads-page-container {
  p {
    margin-bottom: 0.5em;
  }

  .downloads-title-container {
    margin-bottom: 0.7em;
  }

  background-color: var(--background);
  display: flex;
  color: var(--text-content);
  font-weight: 300;

  .table-of-contents-container {
    min-width: 180px;
  }

  .about-content-container {
    overflow: scroll;
    color: var(--text-content);
    padding: 20px 40px;
    background-color: var(--background);

    .doc-section {
      font-size: 0.95rem;
      padding-bottom: 10px;
    }

    .about-section-container {
      .code-text-container {
        margin: 0.9em;

        .code-text {
          font-size: 0.85rem;
        }
      }

      .about-sub-header {
        font-size: 20px;
        font-weight: 400;
      }

      .about-citation {
        color: #8075ff;
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }
}
