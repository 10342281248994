.ambig-result-container-header {
  background-color: var(--background-light) !important;
  margin: 0;
}

.ambig-result-container {
  background-color: var(--soft-background);
}

.categories-table-container {
  background-color: var(--background);
}

.cats-ambig-accordion-summary {
  margin: 0 !important;
}
