.source-type-header {
  margin-bottom: 12px;
}

.sources-page-container {
  padding: 15px;
  .sources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin-bottom: 30px;
  }

  .source-item-card {
    background-color: var(--background-light) !important;
    border: 5px solid var(--background-light);
    border-radius: 5px;

    .source-item-name {
      font-size: 1.2rem;
      font-weight: 700;
      padding: 10px;
      border: 5px;
    }
    .source-versioning {
      float: right bottom;
      font-size: 1rem;
      font-weight: 350;
      color: var(--text-accent);
    }
    .source-section {
      background-color: white;
      margin: 10px;
      padding: 10px;
      border-radius: 5px;
    }
  }
  .filter-buttons {
    flex-wrap: wrap;
    .MuiButton-outlined {
      color: var(--theme-primary) !important;
      border-color: var(--theme-primary) !important;
    }
    .MuiButton-contained {
      background-color: var(--theme-primary) !important;
    }
  }
}
