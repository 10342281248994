@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600&display=swap');

.home-page-container {
  background-color: var(--background);
  font-family: 'Work Sans';
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;

  .home-blurb {
    margin-top: 160px;
    font-size: 20px;
    font-family: 'Work Sans';
    font-weight: 300;
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .search-container {
    min-height: 40px;
    right: 340px;
    margin-top: 20px;

    .search-subcontainer {
      display: flex;

      .search-input {
        max-height: 240px;
        overflow: scroll !important;
        position: relative;

        .search-filters {
          padding: 8px 6px 0 5px;
          cursor: pointer;
          position: absolute;
          top: 1%;
          right: 0.1%;
          bottom: 40%;
          z-index: 1;
        }
      }
    }
  }

  .home-buttons {
    margin-top: 10px;
  }

  .home-links {
    font-family: 'Work Sans';
    font-size: 20px;
    margin-top: 10px;
  }

  .home-footer {
    background-color: var(--theme-primary);
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    font-size: 20px;
    font-family: 'Work Sans';
    font-weight: 300;
  }

  .darkmode-toggle {
    align-self: flex-end;
    position: absolute;
    bottom: 15%;
    margin-right: 5%;
  }
}

.logo {
  font-family: 'Fira Sans', sans-serif;
  font-size: 100px;
  font-weight: 800;
  line-height: 80px;
  margin-bottom: 6px;
  background: -webkit-linear-gradient(
    var(--logo-gradient-1),
    var(--logo-gradient-2)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a {
  color: var(--theme-primary);
  text-decoration: underline;
}

@media screen and (max-width: var(--medium-screen)) {
  .home-footer {
    font-size: 15px !important;
  }
}

@media screen and (max-width: var(--small-screen)) {
}

.MuiButton-outlined {
  color: var(--theme-primary) !important;
  border-color: var(--theme-primary) !important;
}
.MuiButton-contained {
  background-color: var(--theme-primary) !important;
}
