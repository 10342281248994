.about-page-container {
  a {
    color: var(--link) !important;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 15px;
    line-height: 1.5em;
  }

  background-color: var(--background);
  display: flex;
  color: var(--text-content);
  // font-size: 50px;

  font-weight: 300;

  .table-of-contents-container {
    min-width: 180px;
    margin: 2em;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
  }

  .about-content-container {
    overflow: scroll;
    color: var(--text-content);
    padding: 20px 40px;
    background-color: var(--background);

    .doc-section {
      font-size: 0.95rem;
      padding-bottom: 20px;
    }

    .about-section-container {
      .about-sub-header {
        font-size: 20px;
        font-weight: 400;
      }

      .about-citation {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }

  span {
    color: red !important;
  }

  .doc-section {
    font-size: 0.89rem;
  }

  .publications-section-container {
    .about-pub-item {
      padding-bottom: 0.5em;
    }
  }

  .contact-section-container {
    .left-section {
      float: left;
      min-height: 700px;
      width: 40%;
    }
    .right-section {
      width: 40%;
      float: left;
      min-height: 700px;
    }
    .spacing {
      min-height: 700px;
    }
    svg {
      fill: var(--text-primary);
    }
  }

  .faq-section-container {
    .faq-item {
      margin-bottom: 30px;

      .faq-question {
        font-weight: bold;
      }
    }
  }

  .known-section-container {
    li {
      list-style-type: none;
    }
  }
}
