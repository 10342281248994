.unmatched-terms {
  height: fit-content;
  background-color: var(--soft-background) !important;
  border: 2px solid var(--soft-background);
  border-radius: 5px;
  padding: 10px;
}

.ambig-match-accordions {
  margin-bottom: 1em;
}
